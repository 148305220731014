import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FavoriteService } from '@services/utilities/favorite.service';
import { FileService } from '@services/utilities/file.service';
import { LocalStorageService } from '@services/utilities/local-storage.service';
import { Document } from '@models/document.model';

@Component({
  selector: 'thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss']
})
export class ThumbnailComponent implements OnInit {

  @Input() index: number;
  @Input() document: Document;
  @Input() currentHoverIndex: number;
  @Output() updateButtonClicked = new EventEmitter();
  @Output() archiveButtonClicked = new EventEmitter();
  @Output() unarchiveButtonClicked = new EventEmitter();
  @Output() visibilityButtonClicked = new EventEmitter();
  @Output() deleteButtonClicked = new EventEmitter();
  @Output() favoriteButtonClicked = new EventEmitter();
  @Output() removeSharedButtonClicked = new EventEmitter();
  @Output() mouseOverThumbnails = new EventEmitter();

  constructor(
    public favService: FavoriteService,
    public localStorageService: LocalStorageService,
    private fileService: FileService
  ) { }

  ngOnInit() {
  }

  onMouseOverThumbnails(index: number) {
    this.mouseOverThumbnails.emit(index);
  }

  onShow(event:any) {
    this.fileService.getBlob(this.document.path).subscribe(
      (file) => {
        const blob = new Blob([file], { type: this.document.mimeType });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
    );
  }

  onDownloadClicked(event: any) {
    event.stopPropagation();
    this.fileService.getBlob(this.document.path).subscribe(
      (file) => {
        const blob = new Blob([file], { type: this.document.mimeType });
        const downloadUrl = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = this.document.filename;
          document.body.appendChild(a);
          a.click();
        }
      }
    );
  }

  onUpdateClicked(event: any) {
    event.stopPropagation();
    this.updateButtonClicked.emit(this.document);
  }

  onArchiveClicked(event: any) {
    event.stopPropagation();
    this.archiveButtonClicked.emit(this.document);
  }

  onUnarchiveClicked(event: any) {
    event.stopPropagation();
    this.unarchiveButtonClicked.emit(this.document);
  }

  onSetVisibilityClicked(event: any) {
    event.stopPropagation();
    this.visibilityButtonClicked.emit(this.document);
  }

  onDeleteClicked(event: any) {
    event.stopPropagation();
    this.deleteButtonClicked.emit(this.document);
  }

  onUpdateFavoriteStatus(event: any) {
    event.stopPropagation();
    this.favoriteButtonClicked.emit(this.document);
  }

  onRemoveSharedClicked(event:any) {
    event.stopPropagation();
    this.removeSharedButtonClicked.emit(this.document);
  }

  getPComment() {
    return this.document.comment.replace(/\r?\n|\r/g, '<br>');
  }


  hideSharedIcon() {
    if (this.document && !this.document.isShared) {
      return true;
    }
    return false;
  }

}

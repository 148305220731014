<div class="thumbnail-size">
  <div
    class="card card-thumbnail"
    (mouseenter)="onMouseOverThumbnails(index)"
    (mouseleave)="onMouseOverThumbnails(-1)"
    (click)="onShow($event)"
    style="cursor: pointer;">
    <div class="card-content p-0">
      <div class="thumbnails-container">
        <div class="thumbnails-img-container">
          <ng-container *ngIf="document && document.thumbnail; else noThumbnailsDiv">
            <img
              src="data:image/png;base64,{{ document.thumbnail }}"
              [alt]="document.basename"
              width="240">
          </ng-container>
          <ng-template #noThumbnailsDiv>
            <div class="no-thumbnails-container">
              <div class="no-thumbnails">
                <span class="span-opacity-40">Fichier</span>
                <div>
                  <span class="span-uppercase span-bold span-opacity-40">{{ document.extension }}</span>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
        <ng-container *ngIf="index == currentHoverIndex">
          <div class="thumbnails-btn-options">
            <button
              class="btn btn-info btn-circle mt-0"
              title="Télécharger le document"
              (click)="onDownloadClicked($event)">
              <i class="fas fa-download"></i>
            </button>
            <ng-container *ngIf="localStorageService.isEditMode() && !document.isFavorite">
              <button
                class="btn btn-warning btn-circle mt-1"
                title="Éditer le document"
                (click)="onUpdateClicked($event)">
                <i class="fas fa-pen"></i>
              </button>
              <button
                *ngIf="!localStorageService.isArchived()"
                class="btn btn-pages btn-circle mt-1"
                title="Archiver le document"
                (click)="onArchiveClicked($event)">
                <i class="fas fa-archive color-white"></i>
              </button>
              <button
                *ngIf="localStorageService.isArchived()"
                class="btn btn-pages btn-circle mt-1"
                title="Désarchiver le document"
                (click)="onUnarchiveClicked($event)">
                <i class="fas fa-box-open color-white"></i>
              </button>
              <button
                class="btn btn-complete btn-circle mt-1"
                title="Modifier la visibilité"
                (click)="onSetVisibilityClicked($event)">
                <i class="fas fa-eye color-white"></i>
              </button>
              <button
              *ngIf="document.isShared"
              class="btn btn-primary btn-circle mt-1"
              title="Enlever le document partagé"
              (click)="onRemoveSharedClicked($event)">
              <i class="fas fa-unlink color-white"></i>
            </button>
              <button
                class="btn btn-danger btn-circle mt-1"
                title="Supprimer le document"
                (click)="onDeleteClicked($event)">
                <i class="fas fa-trash color-white"></i>
              </button>
            </ng-container>
          </div>
          <div class="thumbnails-btn-favorite p-1">
            <fa-icon
              [icon]="favService.getFavoriteIcon(document.isFavorite)"
              [styles]="{'color': favService.getFavoriteIconColor(document.isFavorite)}"
              [title]="favService.getFavoriteIconTooltip(document.isFavorite)"
              (click)="onUpdateFavoriteStatus($event)">
            </fa-icon>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="card-footer card-footer-custom">
      <div class="thumbnails-description-container">
        <div class="thumbnails-description-item">
          <span
            class="span-size-12 span-overflow span-bold"
            [title]="document.basename">
            {{ document.basename }}
          </span>
          <div>
            <span class="span-size-12 span-opacity-60 pull-left">{{ document.lastModified }}</span>
            <ng-container *ngIf="document.comment && document.comment.length > 0">
              <span
                class="span-size-14 pull-right"
                [tooltip]="commentTooltipTemplate"
                style="cursor: pointer;">
                <i class="fas fa-info-circle"></i>
              </span>
              <ng-template #commentTooltipTemplate>
                <p [innerHtml]="getPComment()"></p>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="!hideSharedIcon()">
              <span class="span-size-14 pull-right mr-2">
                <i class="fas fa-share-alt-square"></i>
              </span>
            </ng-container>
          </div>
          <ng-container *ngIf="localStorageService.isEditMode() && document.marketsTypes">
            <div class="d-flex justify-content-center mt-1">
              <i
                *ngFor="let marketType of document.marketsTypes; let i = index"
                class="fas tll-18 {{marketType.tllIcon}}"
                [ngClass]="{'ml-1': i > 0}"
                [title]="marketType.name">
              </i>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

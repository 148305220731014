import Swal from 'sweetalert2';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService } from '@services/utilities/modal.service';
import { LocalStorageService } from '@services/utilities/local-storage.service';
import { SwalService } from '@services/utilities/swal.service';
import { Folder } from '@models/folder.model';
import { Category } from '@models/category.model';
import { Subcategory } from '@models/subcategory.model';
import { FolderService } from '@services/folder.service';
import { CategoryService } from '@services/category.service';

@Component({
  selector: 'app-documentation-folders',
  templateUrl: './documentation-folders.component.html',
  styleUrls: ['./documentation-folders.component.scss']
})
export class DocumentationFoldersComponent implements OnInit {

  @Input() folders: Folder[];
  @Input() category: Category;
  @Input() subcategory: Subcategory;
  @Input() selectedFolder: Folder;
  @Output() folderSelected = new EventEmitter();
  public isEditFolder = false;
  public categories: Category[] = [];
  public subcategories: Subcategory[] = [];

  constructor(
    public localStorageService: LocalStorageService,
    private folderService: FolderService,
    private modalService: ModalService,
    private swalService: SwalService,
    private categoryService: CategoryService,
  ) { }

  ngOnInit() {
    this.categoryService.getAllForCopy().subscribe({
      next:(categories) => {
        this.categories = categories;
      }
    });
  }

  onFolderSelected(folder: Folder) {
    this.folderSelected.emit(folder);
  }

  onAddClicked() {
    this.isEditFolder = false;
    this.modalService.showFolderModal();
  }

  onUpdateClicked() {
    this.isEditFolder = true;
    this.modalService.showFolderModal();
  }

  onPinClicked() {
    if (this.selectedFolder) {
      this.folderService.pin({
        id: this.selectedFolder.id,
        categoryRoute: this.category.route,
        subcategoryRoute: this.subcategory ? this.subcategory.route : null
      });
    }
  }

  onUnpinClicked() {
    if (this.selectedFolder) {
      this.folderService.unpin({
        id: this.selectedFolder.id,
        categoryRoute: this.category.route,
        subcategoryRoute: this.subcategory ? this.subcategory.route : null
      });
    }
  }

  onCopyClicked(): void {
    this.modalService.showFolderCopyModal();
  }

  onDeleteClicked() {
    if (this.selectedFolder) {
      const swalOptions = this.swalService.getSwalDeleteOptions({
        text: `Le dossier ${this.selectedFolder.name} sera supprimé de manière irréversible, ainsi que tous ses documents, liens et archives associés.`
      });
      Swal.fire(swalOptions).then((result) => {
        if (result.value) {
          this.folderService.delete({
            id: this.selectedFolder.id,
            categoryRoute: this.category.route,
            subcategoryRoute: this.subcategory ? this.subcategory.route : null
          });
        }
      });
    }
  }
}

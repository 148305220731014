import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import * as jwt_decode from 'jwt-decode';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class AuthenticationService {
    public token: string;
    private headers: HttpHeaders;
    private readonly apiUrl = environment.apiUrl;
    private readonly baseUrl = environment.baseUrl;
    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private http: HttpClient,
        private router: Router,
        private localStorageService: LocalStorageService
    ) {
        this.headers = new HttpHeaders();
        this.headers.append("Content-Type", 'application/json');
        this.headers.append("Access-Control-Allow-Origin", "*");
        this.headers.append("Access-Control-Allow-Headers", "Origin, Authorization, Content-Type, Accept");

        // set token if saved in local storage
        var currentUser = JSON.parse(localStorage.getItem('user'));
        this.token = currentUser && currentUser.token;
    }

    login(form: FormGroup): Observable<any> {
        return this.http.post(this.apiUrl + '/authenticate', form.value, { headers: this.headers })
            .pipe(
                map((response: Response) => {
                    this.token = response['accessToken'];
                    let email = response['email'];
                    let selectedMarketsTypes = response['marketsTypes'];
                    if (this.token) {
                        localStorage.setItem('user',
                            JSON.stringify({ email: email, token: this.token }));
                        this.loggedIn.next(true);
                        this.localStorageService.setSelectedMarketsTypes(selectedMarketsTypes);
                        this.router.navigate(['/accueil']);
                    }
                    return response;
                })
            );
    }

    register(username: string, email: string, password: string): Observable<any> {
        return this.http.post(this.apiUrl + '/register', {
            email: email, name: username,
            password: password
        }, { headers: this.headers })
            .pipe(
                map((response: Response) => {
                    this.token = response['accessToken'];
                    let email = response['email'];
                    if (this.token) {
                        localStorage.setItem('user',
                            JSON.stringify({ email: email, token: this.token }));
                    }
                    return response;
                })
            );
    }

    logout(): void {
        this.token = null;
        localStorage.removeItem('user');
    }

    sendPasswordResetEmail(email: string): Observable<any> {
        let url = this.baseUrl + '/reset-password';
        return this.http.post(this.apiUrl + '/password-reset-email',
            { email: email, url: url }, { headers: this.headers })
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    resetPassword(newPassword: string, confirmedPassword: string, token: string): Observable<any> {
        return this.http.post(this.apiUrl + '/reset-password', {
            password: newPassword,
            confirm_password: confirmedPassword, token: token
        }, { headers: this.headers })
            .pipe(
                map((response: Response) => {
                    return response;
                })
            );
    }

    setToken(token) {
        this.token = token;
    }

    public getToken(): string {
        return this.token;
    }


    getTokenExpirationDate(token: string): Date {
        const decoded = jwt_decode(token);

        if (decoded.exp === undefined) return null;

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }


    getUserInfos(token?: string): any {
        if (!token) token = this.getToken();
        if (!token) return false;
        const decoded = jwt_decode(token);

        if (decoded.lastname === undefined) return null;
        return {
            id: decoded.id,
            lastname: decoded.lastname,
            firstname: decoded.firstname,
            initials: decoded.initials,
        }
    }

    getRoles(token: string): any {
        const decoded = jwt_decode(token);

        if (decoded.roles === undefined) return null;
        return decoded.roles;
    }

    isTokenExpired(token?: string): boolean {
        if (!token) token = this.getToken();
        if (!token) return true;

        const date = this.getTokenExpirationDate(token);
        if (date === undefined) return false;
        return !(date.valueOf() > new Date().valueOf());
    }

    isAdmin(token?: string): boolean {
        if (!token) token = this.getToken();
        if (!token) return false;

        const roles = this.getRoles(token);
        if (roles != undefined) {
            return roles.includes('admin');
        }
        return false;
    }

}
import Swal from 'sweetalert2';
import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageService } from '@services/utilities/local-storage.service';
import { Document } from '@models/document.model';
import { SwalService } from '@services/utilities/swal.service';
import { ModalService } from '@services/utilities/modal.service';
import { Folder } from '@models/folder.model';
import { Category } from '@models/category.model';
import { Subcategory } from '@models/subcategory.model';
import { DocumentService } from '@services/document.service';

@Component({
  selector: 'app-documentation-documents',
  templateUrl: './documentation-documents.component.html',
  styleUrls: ['./documentation-documents.component.scss']
})
export class DocumentationDocumentsComponent implements OnInit {

  @Input() selectedFolder: Folder;
  @Input() category: Category;
  @Input() subcategory: Subcategory;
  public currentHoverIndex = -1;
  public selectedDocument: Document = null;

  constructor(
    public localStorageService: LocalStorageService,
    private documentService: DocumentService,
    private modalService: ModalService,
    private swalService: SwalService
  ) { }

  ngOnInit() {
  }

  getThumbnails() {
    if (this.selectedFolder) {
      return this.selectedFolder.documents;
    } else {
      return [];
    }
  }

  onUpdateDocumentClicked(document: Document) {
    this.selectedDocument = document;
    this.modalService.showDocumentModal();
  }

  onImportDocumentsClicked() {
    this.modalService.showImportDocumentsModal();
  }

  onAddSharedDocumentClicked(): void {
    this.modalService.showSharedDocumentModalModal();
  }

  onArchiveDocumentClicked(document: Document) {
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: `Le document ${document.filename} apparaîtra désormais dans les archives.`
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.documentService.archive({
          id: document.id,
          list: {
            categoryRoute: this.category.route,
            subcategoryRoute: this.subcategory ? this.subcategory.route : null
          }
        });
      }
    });
  }

  onUnarchiveDocumentClicked(document: Document) {
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: `Le document ${document.filename} n'apparaîtra plus dans les archives.`
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.documentService.unarchive({
          id: document.id,
          list: {
            categoryRoute: this.category.route,
            subcategoryRoute: this.subcategory ? this.subcategory.route : null
          }
        });
      }
    });
  }

  onSetVisibilityDocumentClicked(document: Document) {
    this.selectedDocument = document;
    this.modalService.showDocumentVisibilityModal();
  }

  onDeleteDocumentClicked(document: Document) {
    const swalOptions = this.swalService.getSwalDeleteOptions({
      text: `Le document ${document.filename} sera supprimé de manière irréversible.`
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.documentService.delete({
          id: document.id,
          list: {
            categoryRoute: this.category.route,
            subcategoryRoute: this.subcategory ? this.subcategory.route : null
          }
        });
      }
    });
  }

  onUpdateFavoriteStatus(document: Document) {
    if (document.isFavorite) {
      this.documentService.unmarkAsFavorite({
        id: document.id,
        list: {
          categoryRoute: this.category ? this.category.route : null,
          subcategoryRoute: this.subcategory ? this.subcategory.route : null
        }
      });
    } else {
      this.documentService.markAsFavorite({
        id: document.id,
        list: {
          categoryRoute: this.category ? this.category.route : null,
          subcategoryRoute: this.subcategory ? this.subcategory.route : null
        }
      });
    }
  }

  onRemoveSharedDocumentClicked(document: Document) {
    if (this.selectedFolder) {
      const swalOptions = this.swalService.getSwalConfirmOptions({
        text: `Le document ${document.basename} sera enlevé pour ce dossier uniquement, il ne sera pas supprimé pour tous les autres.`
      });
      Swal.fire(swalOptions).then((result) => {
        if (result.value) {
          this.documentService.removeShared({
            id: document.id,
            list: {
              categoryRoute: this.category.route,
              subcategoryRoute: this.subcategory ? this.subcategory.route : null
            }
          }, this.selectedFolder.id);
        }
      });
    }
  }

}

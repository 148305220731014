import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { Category } from '@models/category.model';
import { Document } from '@models/document.model';
import { Folder } from '@models/folder.model';
import { Subcategory } from '@models/subcategory.model';
import { SubmitButton } from '@models/submit-button.model';
import { DocumentService } from '@services/document.service';
import { ModalService } from '@services/utilities/modal.service';
import { SubmitButtonService } from '@services/utilities/submit-button.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-shared-document',
  templateUrl: './modal-shared-document.component.html',
  styleUrls: ['./modal-shared-document.component.scss']
})
export class ModalSharedDocumentComponent implements OnInit, AfterViewInit {
  @Input() selectedFolder: Folder;
  @Input() category: Category;
  @Input() subcategory: Subcategory;
  public documents: Document[] = [];
  public submitButton: SubmitButton;
  public selectedDocumentId: number;
  @ViewChild('modalSharedDocument', { static: false }) childModal: ModalDirective;

  constructor(
    private documentService: DocumentService,
    private submitButtonService: SubmitButtonService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.modalService.setSharedDocumentModalModal(this.childModal);
  }

  onModalShown() {
    this.documentService.getAll().subscribe({
      next:(documents) => {
        this.documents = documents;
      }
    })
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      text: 'Ajouter',
      objectName: 'le document partagé',
      icon: faShareAlt
    });
    this.documentService.setSharedSubmitButton(this.submitButton);
  }

  onModalHidden() {
    this.documents = [];
    this.selectedDocumentId = null;
  }

  isValidButton() {
    return this.selectedDocumentId != null && this.selectedFolder != null;
  }

  closeModal() {
    this.childModal.hide();
  }

  onAddSharedClicked() {
    if (this.isValidButton()) {
      this.documentService.addShared({
        id: this.selectedDocumentId,
        list: {
          categoryRoute: this.category.route,
          subcategoryRoute: this.subcategory ? this.subcategory.route : null
        }
      }, this.selectedFolder.id);
    }
  }

}

import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Category } from '@models/category.model';
import { Folder } from '@models/folder.model';
import { Subcategory } from '@models/subcategory.model';
import { SubmitButton } from '@models/submit-button.model';
import { FolderService } from '@services/folder.service';
import { ModalService } from '@services/utilities/modal.service';
import { SubmitButtonService } from '@services/utilities/submit-button.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-copy-folder',
  templateUrl: './modal-copy-folder.component.html',
  styleUrls: ['./modal-copy-folder.component.scss']
})
export class ModalCopyFolderComponent implements OnInit, AfterViewInit {
  @Input() categories: Category[] = [];
  @Input() category: Category;
  @Input() subcategory: Subcategory;
  public form: FormGroup;
  @ViewChild('modalFolderCopy', { static: false }) childModal: ModalDirective;
  public submitButton: SubmitButton;
  @Input() folder: Folder;

  constructor(
    private submitButtonService: SubmitButtonService,
    private folderService: FolderService,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.modalService.setFolderCopyModal(this.childModal);
  }

  onModalShown() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      text: 'Copier',
      objectName: 'le dossier',
      icon: faCopy
    });
    this.folderService.setSubmitButton(this.submitButton);
    this.form = this.folderService.getCopyForm({
      categoryId: this.category.id,
      subcategoryId: this.subcategory ? this.subcategory.id : null,
      folder: this.folder,
      copyFiles: true
    });
  }

  onModalHidden() {
    this.form = null;
  }

  isValidForm() {
    return this.form.valid;
  }

  closeModal() {
    this.childModal.hide();
  }

  onCategoryChange() {
    this.form.patchValue({
      subcategoryId : null
    })
  }

  getSubcategoriesItems() {
    if (this.form.value.categoryId) {
      const category = this.categories.find(x => x.id === this.form.value.categoryId);
      return category.subcategories;
    }
    return [];
  }

  submitForm() {
    if (this.isValidForm()) {
      this.folderService.copy({
        id: this.folder.id,
        form: this.form,
        categoryRoute: this.category.route,
        subcategoryRoute: this.subcategory ? this.subcategory.route : null
      });
    }
  }

}

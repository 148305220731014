<div
  bsModal
  #modalSharedDocument="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-sizes-name1"
  (onShown)="onModalShown()"
  (onHidden)="onModalHidden()"
  [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4
          id="dialog-sizes-name1"
          class="modal-title pull-left">
          Ajout d'un document partagé
        </h4>
        <button
          type="button"
          class="close pull-right"
          (click)="closeModal()"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mt-5">
        <ng-select
          [items]="documents"
          [multiple]="false"
          [virtualScroll]="true"
          [(ngModel)]="selectedDocumentId"
          placeholder="Rechercher parmi les documents existants"
          notFoundText="Aucun élément trouvé"
          bindLabel="name"
          bindValue="id">
          <ng-template
            ng-label-tmp
            let-item="item">
            <ng-container
              [ngTemplateOutlet]="ngSelectItemTemplate"
              [ngTemplateOutletContext]="{item: item}">
            </ng-container>
          </ng-template>
          <ng-template
            ng-option-tmp
            let-item="item">
            <ng-container
              [ngTemplateOutlet]="ngSelectItemTemplate"
              [ngTemplateOutletContext]="{item: item}">
            </ng-container>
          </ng-template>
        </ng-select>
      </div>
      <div class="modal-footer justify-content-center">
        <submit-button
          *ngIf="submitButton"
          [isValidForm]="isValidButton()"
          [submitButton]="submitButton"
          (onButtonClicked)="onAddSharedClicked()">
        </submit-button>
      </div>
    </div>
  </div>
</div>
<ng-template
  #ngSelectItemTemplate
  let-item="item">
  <div class="d-flex align-items-center">
    <span class="pl-1">{{item.basename}}</span>
  </div>
</ng-template>

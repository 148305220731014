import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Document } from '@models/document.model';
import { SubmitButton } from '@models/submit-button.model';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { FolderService, GetForListFavoriteParam, GetForListParam } from './folder.service';
import { ApiService } from './utilities/api.service';
import { FavoriteService } from './utilities/favorite.service';
import { LocalStorageService } from './utilities/local-storage.service';
import { ModalService } from './utilities/modal.service';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';

export interface DocumentRenameParam {
    id: number;
    form: FormGroup;
    list: GetForListParam;
}

export interface DocumentIdParam {
    id: number;
    list: GetForListParam;
}

export interface DocumentFavoriteParam {
    id: number;
    list?: GetForListFavoriteParam
}

@Injectable()
export class DocumentService {

    private route = '/documents';
    private submitButton: SubmitButton;
    private sharedSubmitButton: SubmitButton;
    private visibilitySubmitButton: SubmitButton;

    constructor(
        private folderService: FolderService,
        private favoriteService: FavoriteService,
        private apiService: ApiService,
        private notifService: NotifService,
        private modalService: ModalService,
        private formBuilder: FormBuilder,
        private submitButtonService: SubmitButtonService,
        private localStorageService: LocalStorageService
    ) { }

    setSubmitButton(button: SubmitButton): void {
        this.submitButton = button;
    }

    setVisibilitySubmitButton(button: SubmitButton): void {
        this.visibilitySubmitButton = button;
    }

    setSharedSubmitButton(button: SubmitButton): void {
        this.sharedSubmitButton = button;
    }


    getForm(document: Document): FormGroup {
        return this.formBuilder.group({
            filename: [document.filename, Validators.required],
            comment: document.comment ? document.comment : ''
        });
    }

    getAll(): Observable<Document[]> {
        return this.apiService.get(`${this.route}`)
    }

    update(param: DocumentRenameParam): void {
        this.submitButtonService.setDisabled(this.submitButton);
        this.apiService.put(`${this.route}/${param.id}`, param.form.value)
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
                    this.modalService.hideDocumentModal();
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    archive(param: DocumentIdParam): void {
        this.apiService.get(`${this.route}/${param.id}/archive`).subscribe(
            (success) => {
                this.notifService.showSuccessNotif(success);
                this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    unarchive(param: DocumentIdParam): void {
        this.apiService.get(`${this.route}/${param.id}/unarchive`).subscribe(
            (success) => {
                this.notifService.showSuccessNotif(success);
                this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    import(folderId: number, file: File): Observable<any> {
        const formData = new FormData();
        formData.append("folderId", folderId.toString());
        formData.append("selectedMarketsTypes", JSON.stringify(this.localStorageService.getSelectedMarketsTypesIds()));
        formData.append("file", file);
        return this.apiService.importFile(`${this.route}/import`, formData);
    }

    delete(param: DocumentIdParam): void {
        this.apiService.delete(this.route + '/' + param.id)
            .subscribe(
                (success) => {
                    this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
                    this.notifService.showSuccessNotif(success);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    markAsFavorite(param: DocumentFavoriteParam): void {
        this.apiService.get(`${this.route}/${param.id}/mark-as-favorite`).subscribe(
            (success) => {
                this.notifService.showSuccessNotif(success);
                if (param.list.categoryRoute) {
                    this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
                } else {
                    this.favoriteService.getFavorites();
                }
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    unmarkAsFavorite(param: DocumentFavoriteParam): void {
        this.apiService.get(`${this.route}/${param.id}/unmark-as-favorite`).subscribe(
            (success) => {
                this.notifService.showSuccessNotif(success);
                if (param.list) {
                    this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
                } else {
                    this.favoriteService.getFavorites();
                }
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    setVisibility(param: DocumentIdParam, selectedMarketsTypes: number[]): void {
        this.submitButtonService.setDisabled(this.visibilitySubmitButton);
        this.apiService.put(`${this.route}/${param.id}/set-visibility`, {
            selectedMarketsTypes: JSON.stringify(selectedMarketsTypes)
        })
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.visibilitySubmitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
                    this.modalService.hideDocumentVisibilityModal();
                    this.notifService.showSuccessNotif(success);
                }
            );
    }


    addShared(param: DocumentIdParam, folderId: number): void {
        this.submitButtonService.setDisabled(this.sharedSubmitButton);
        this.apiService.put(`${this.route}/${param.id}/add-shared`, {
            folderId: JSON.stringify(folderId)
        })
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.sharedSubmitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
                    this.modalService.hideSharedDocumentModalModal();
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    removeShared(param: DocumentIdParam, folderId: number): void {
        this.apiService.put(`${this.route}/${param.id}/remove-shared`, {
            folderId: JSON.stringify(folderId)
        })
            .subscribe(
                (success) => {
                    this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
                    this.notifService.showSuccessNotif(success);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }
}

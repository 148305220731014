import { AuthenticationService } from '@services/utilities/authentication.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@environments/environment';

@Component({
  selector: 'eros-front-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  public email = '';
  public password = '';
  private warningMessage: string;
  public form: FormGroup;
  public isLoading = false;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder
  ) {
    this.form = this.initForm();
  }

  ngOnInit(): void {
  }

  onLogIn() {
    this.authService.login(this.form)
      .subscribe(res => {
        //check for errors
        this.warningMessage = '';
        if (Array.isArray(res)) {
          this.warningMessage += res[0];
        }
        // if not errors - navigate to home
        if (!this.warningMessage)
          this.router.navigate(['accueil']);
      }, error => {
        this.warningMessage = "Invalid Credentials!";
        console.error(error);
      });
  }

  initForm() {
    return this.formBuilder.group({
      email: [null, Validators.required],
      password: [null, Validators.required]
    });
  }


  loginWithMicrosoft(e) {
    this.isLoading = true;
    const originUrl = this.getOriginUrl(environment.apiUrl);
    window.location.href = `${originUrl}login-with-microsoft`;
    e.preventDefault();
  }

  private getOriginUrl(apiUrl) {
    // regex api à la fin de l'adresse
    // eslint-disable-next-line prefer-regex-literals
    const originUrl = apiUrl.replace(new RegExp('api$'), '');
    return originUrl;
  }


}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@services/utilities/authentication.service';
import { LocalStorageService } from '@services/utilities/local-storage.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private localStorageService: LocalStorageService,
  ) {
    this.route.queryParams
      .subscribe(params => {
        if (params.email !== "" && params.token !== "") {
          // store email and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('user',
            JSON.stringify({ email: params.email, token: params.token }));
          this.authService.setToken(params.token);
          let selectedMarketsTypes = JSON.parse(params.marketsTypes);

          this.loggedIn.next(true);
          console.log(params);
          this.localStorageService.setSelectedMarketsTypes(selectedMarketsTypes);
          this.router.navigate(['accueil']);
        }
      });
  }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { User } from '@models/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './utilities/api.service';
import { NotifService } from './utilities/notif.service';
import { select } from 'd3';
import { JsonResponse } from '@models/json-response.model';

@Injectable()
export class UserService {

    private route = '/users';
    public users$ = new BehaviorSubject<User[]>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService
    ) { }

    update(id: number, selectedMarketsTypesIds: number[]): Observable<JsonResponse> {
        return this.apiService.put(`${this.route}/${id}`, {
            marketsTypes: selectedMarketsTypesIds,
        });
    }

    getForSelect(): void {
        this.apiService.get(`${this.route}/select`)
            .subscribe(
                (users: User[]) => {
                    this.users$.next(users);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }
}

<ng-container *ngIf="canDisplayComment()">
  <div class="card">
    <div class="card-body p-2">
      <div class="d-flex">
        <span class="span-large">
          <b>Remarque</b>
        </span>
        <ng-container *ngIf="localStorageService.isEditMode()">
          <div class="ml-auto">
            <button
              class="btn btn-success btn-circle"
              title="Enregistrer la remarque"
              [disabled]="selectedFolder == null"
              (click)="onSaveCommentClicked()">
              <i class="fas fa-save"></i>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <ng-container *ngIf="localStorageService.isEditMode() && form">
        <form
          autocomplete="off"
          [formGroup]="form">
          <quill-editor
          [modules]="modules"
          [styles]="{height: '400px'}"
          formControlName="comment"></quill-editor>
        </form>
      </ng-container>
      <ng-container *ngIf="!localStorageService.isEditMode() && this.selectedFolder">
        <div [innerHTML]="getPComment()">
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

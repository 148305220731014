import { Injectable } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Injectable()
export class ModalService {

    private categoryModal: ModalDirective;
    private categoryReorderModal: ModalDirective;
    private folderModal: ModalDirective;
    private folderCopyModal: ModalDirective;
    private documentModal: ModalDirective;
    private importDocumentsModal: ModalDirective;
    private linkModal: ModalDirective;
    private marketTypeModal: ModalDirective;
    private documentVisibilityModal: ModalDirective;
    private linkVisibilityModal: ModalDirective;
    private sharedLinkModal: ModalDirective;
    private sharedDocumentModal: ModalDirective;
    private notificationsModal: ModalDirective;

    setCategoryModal(modal: ModalDirective): void {
        this.categoryModal = modal;
    }

    showCategoryModal(): void {
        this.categoryModal.show();
    }

    hideCategoryModal(): void {
        this.categoryModal.hide();
    }

    setCategoryReorderModal(modal: ModalDirective): void {
        this.categoryReorderModal = modal;
    }

    showCategoryReorderModal(): void {
        this.categoryReorderModal.show();
    }

    hideCategoryReorderModal(): void {
        this.categoryReorderModal.hide();
    }

    //Folder modal
    setFolderModal(modal: ModalDirective): void {
        this.folderModal = modal;
    }

    showFolderModal(): void {
        this.folderModal.show();
    }

    hideFolderModal(): void {
        this.folderModal.hide();
    }

    //Folder copy
    setFolderCopyModal(modal: ModalDirective): void {
        this.folderCopyModal = modal;
    }

    showFolderCopyModal(): void {
        this.folderCopyModal.show();
    }

    hideFolderCopyModal(): void {
        this.folderCopyModal.hide();
    }

    //Document moodal
    setDocumentModal(modal: ModalDirective): void {
        this.documentModal = modal;
    }

    showDocumentModal(): void {
        this.documentModal.show();
    }

    hideDocumentModal(): void {
        this.documentModal.hide();
    }

    setImportDocumentsModal(modal: ModalDirective): void {
        this.importDocumentsModal = modal;
    }

    showImportDocumentsModal(): void {
        this.importDocumentsModal.show();
    }

    hideImportDocumentsModal(): void {
        this.importDocumentsModal.hide();
    }

    setLinkModal(modal: ModalDirective): void {
        this.linkModal = modal;
    }

    showLinkModal(): void {
        this.linkModal.show();
    }

    hideLinkModal(): void {
        this.linkModal.hide();
    }

    setMarketTypeModal(modal: ModalDirective): void {
        this.marketTypeModal = modal;
    }

    showMarketTypeModal(): void {
        this.marketTypeModal.show();
    }

    hideMarketTypeModal(): void {
        this.marketTypeModal.hide();
    }

    setDocumentVisibilityModal(modal: ModalDirective): void {
        this.documentVisibilityModal = modal;
    }

    showDocumentVisibilityModal(): void {
        this.documentVisibilityModal.show();
    }

    hideDocumentVisibilityModal(): void {
        this.documentVisibilityModal.hide();
    }

    setLinkVisibilityModal(modal: ModalDirective): void {
        this.linkVisibilityModal = modal;
    }

    showLinkVisibilityModal(): void {
        this.linkVisibilityModal.show();
    }

    hideLinkVisibilityModal(): void {
        this.linkVisibilityModal.hide();
    }

    setSharedLinkModalModal(modal: ModalDirective): void {
        this.sharedLinkModal = modal;
    }

    showSharedLinkModalModal(): void {
        this.sharedLinkModal.show();
    }

    hideSharedLinkModalModal(): void {
        this.sharedLinkModal.hide();
    }

    // Document shared modal
    setSharedDocumentModalModal(modal: ModalDirective): void {
        this.sharedDocumentModal = modal;
    }

    showSharedDocumentModalModal(): void {
        this.sharedDocumentModal.show();
    }

    hideSharedDocumentModalModal(): void {
        this.sharedDocumentModal.hide();
    }

    setNotificationsModal(modal: ModalDirective): void {
        this.notificationsModal = modal;
    }

    showNotificationsModal(): void {
        this.notificationsModal.show();
    }

    hideNotificationsModal(): void {
        this.notificationsModal.hide();
    }
}
